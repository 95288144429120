/**
 * Data used in the portfolio website
 * 
 * This class stores all the data (strings) used to render pages. It helps keep the HTML and string data separate and is easy to maintain.
 * 
 * Any text that needs to be updated can easily be done here without messing around in HTML. When writing HTML, using a separate data objects like this helps write HTML once and loop through the data. This keeps lists & grids simple without having to repeat the same HTML or components that only display different strings.
 * 
 * When adding a new data object, make sure to export it here and then import in the file that wants to use it.
 * 
 * Do not change the "id"s as they map to image file names. Graph ql uses that to fetch dynamic images and render them responsively 
 * 
 * @see https://stackoverflow.com/questions/46039976/exporting-multiple-modules-in-react-js
 * 
 */

export const dataSelectWorks = [
    {
        id: "amazontokens",
        title: "Amazon Design Tokens",
        subtitle: "Unifying token naming for all design system teams",
        url: "/casestudy/amazon-design-tokens"
    },
    {
        id: "bam",
        title: "BuzzFeed Ad Manager",
        subtitle: "Enterprise software that improved ad creation experience",
        url: "/casestudy/buzzfeed-ad-manager"
    },
    {
        id: "bfappds",
        title: "BuzzFeed App Design System",
        subtitle: "Building a design system that halved design QA time",
        url: "/casestudy/design-system-buzzfeed-app"
    },
    {
        id: "buzzfeed",
        title: "BuzzFeed+",
        subtitle: "Launching an app subscription in 1 month",
        url: "/casestudy/buzzfeed-plus"
    },

    // {
    //     id: "carpoolworld",
    //     title: "CarpoolWorld",
    //     subtitle: "App redesign to improve user retention and engagement",
    //     url: "/casestudy/carpoolworld"
    // },
    // {
    //     id: "gmaps",
    //     title: "Google Maps",
    //     subtitle: "Discovered ways to improve Google Maps via various usability tests",
    //     url: "/casestudy/google-maps-usability-evaluation"
    // },
    // {
    //     id: "causehero",
    //     title: "Cause Hero",
    //     subtitle: "Gamified iOS app to make volunteering for causes fun",
    //     url: "/casestudy/cause-hero"
    // },
]

export const dataBlogArticles = [
    {
        id: "blog-horizontallist",
        title: "Best Practices For Horizontal Scrolling In Mobile",
        url: "https://uxdesign.cc/best-practices-for-horizontal-lists-in-mobile-21480b9b73e5"
    },
    {
        id: "blog-emptystate",
        title: "Using Empty States To Your Advantage",
        url: "https://uxdesign.cc/use-empty-states-to-your-advantage-1ba760cd739f"
    },
    {
        id: "blog-skeleton",
        title: "Stop Using Loading Spinners, There's Something Better",
        url: "https://uxdesign.cc/stop-using-a-loading-spinner-theres-something-better-d186194f771e"
    },
]

export const dataArtworks = [
    { id: "art-chadwick", alt: "" },
    { id: "art-niagara", alt: "" },
    { id: "art-babyyoda", alt: "" },
    { id: "art-portraitstudy", alt: "" },
]

export const dataPlay = [
    {
        id: "themeableds",
        title: "Themeable Design System",
        subtitle: "CodeSandbox demo for a design system implementation using design tokens for the web",
        url: "https://codesandbox.io/s/react-components-playground-x78wn?file=/README.md",
        newTab: true,
    },
    {
        id: "newslet",
        title: "Newslet",
        subtitle: "Personal project to improve a news reader app for Android",
        url: "https://suleiman19.medium.com/newslet-v2-27c59f47434c",
        newTab: true,
    },
    {
        id: "spotifyanim",
        title: "Spotify Animation",
        subtitle: "Recreating the Spotify apps's music player transition using Framer Motion",
        url: "https://codesandbox.io/s/spotify-bottom-sheet-cyfsi?file=/src/MusicSheet.tsx",
        newTab: true,
    },
    {
        id: "figmaplugin",
        title: "Figma Design Tokens plugin",
        subtitle: "One click to sync your Figma styles with a remote tokens repository",
        url: "https://www.figma.com/community/plugin/1128196356923537195",
        newTab: true,
    }
]