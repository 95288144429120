import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"

/**
 *
 * A borderless, transparent card style built upon Bootstrap's base card style.
 * Used to display portfolio case studies in a grid.
 * Supports a list style too
 * 
 * @see https://getbootstrap.com/docs/4.6/components/card/
 * @export
 * @param {string} id helps identify unique case studies and differentiate them from others
 * @param {string} url link to case study
 * @param {string} title project title
 * @param {string} subtitle two liner that briefly conveys what the project is about
 * @param {src} imgUrl hero image for project
 * @param {boolean} newTab indicate whether a URL should open in a new tab
 * @param {} imgObj param to receive gatsbyimage data object via graph ql
 * @return {*} 
 */
export default function ContentCard({ type, id, url, title, subtitle, imgUrl, newTab, imgObj }) {

    // const cardImg = <img src={img} className="card-img-top rounded border" alt="..." />

    const cardImg = <GatsbyImage className="card-img-top rounded border" image={imgObj} alt="..." />

    // Used for de-emphasized case studies
    // Displayed as a horizontal list item
    const portfolioListCard =
        <a id={id} href={url} className="card card-portfolio flex-row">
            <div className="col-sm-4 p-0">
                {cardImg}
            </div>
            <div className="col-sm-8 d-flex align-items-center pe-0">
                <div className="card-body pt-0 pt-md-3ms-0ms-md-2">
                    <h3 className="card-title mb-1 mb-md-2">{title}</h3>
                    <p className="card-text">{subtitle}</p>
                </div>
            </div>
        </a>

    // Used strictly for case studies
    // Supports title + text
    // Displayed as a grid item
    const portfolioGridCard =
        <a id={id} href={url} className="card card-portfolio" target={`${newTab ? "_blank" : "_self"}`}>
            {cardImg}
            <div className="card-body pt-3">
                <h3 className="card-title mb-1 mb-md-2">{title}</h3>
                <p className="card-text">{subtitle}</p>
            </div>
        </a>

    // For any other content that uses an image+title
    // Example: Blog post highlights
    // Displayed as a grid item
    const regularCard =
        <a id={id} href={url} className="card text-decoration-none" target="_blank" rel="noreferrer">
            {cardImg}
            <div className="card-body pt-2">
                <p className="card-title mb-0"><strong>{title}</strong></p>
            </div>
        </a>

    const portfolioCard = type !== null && type === "list" ?
        portfolioListCard : portfolioGridCard

    return subtitle.length > 0 ? portfolioCard : regularCard
}

ContentCard.defaultProps = {
    type: null,
    id: "",
    url: "#",
    title: "",
    subtitle: "",
    img: "",
    newTab: false,
}