/** 
 * 
 * Home page
 * 
*/
import React from "react"
import { getImage } from "gatsby-plugin-image"
import { graphql } from 'gatsby'
import { motion } from 'framer-motion';

import Layout from "../components/Layout"
import ContentCard from "../components/ContentCard"
import { Mail } from 'react-feather'
import { dataSelectWorks, dataBlogArticles } from "../data/DataSite"

const containerVariants = {
  hidden: { opacity: 0, y: 40 },
  visible: { opacity: 1, y: 0, transition: { delay: 0.2, staggerChildren: 0.2, delayChildren: 0.2, } }
};

const itemVariants = {
  hidden: { opacity: 0, y: 40 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.4,
      type: "spring",
      stiffness: 80,
      damping: 6,
      mass: 0.3
    }
  }
};

const titleVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: {
    opacity: 1,
    y: 0,
    transition: { ease: "easeOut", delay: 0.2 }
  }
};


export default function Home({ data }) {
  return (
    <Layout children={
      <>
        {/* Start hero section */}
        <section className=" mt-3 mt-md-5">
          <div className="mb-0 pb-0 pt-5">
            <motion.div className="container mt-3 mt-md-5"
              variants={titleVariants}
              initial="hidden"
              animate="visible">
              <div className="row">
                {/* Copy + CTA */}
                <div className="col-sm-12 d-flex align-content-center flex-wrap">
                  <h1 className="display-5">A curious designer passionate about solving problems, code, and art.</h1>
                  <p className="mt-2 lead text-secondary">Working on next-generation design systems at Amazon.</p>
                </div>

                {/* Hero Image */}
                {/* <div className="col-md-5 order-first order-md-last my-4 my-md-5 px-5 px-md-0">
                  <StaticImage
                    className="mx-auto d-block float-md-right"
                    loading="eager"
                    placeholder="none"
                    src="../../static/img/me_side_face.png"
                    alt="Suleiman Ali Shakir UX Portfolio"
                  />
                </div> */}

                <div className="col-12 mb-md-5 mt-4">
                  <div className="d-flex flex-row align-items-start text-secondary small">
                    <span>Get in touch:</span>
                    <div class="d-flex flex-row ms-2">
                      <a href="https://www.linkedin.com/in/suleiman-shakir" role="button" aria-label="LinkedIn">LinkedIn</a>
                      <span className="text-body-tertiary mx-1 mx-sm-2" aria-hidden="true">•</span>
                      <a href="https://adplist.org/mentors/suleiman-ali-shakir" role="button" aria-label="Mentorship on ADPList">Mentorship</a>
                    </div>
                  </div>
                </div>
              </div>
            </motion.div>
          </div>
        </section>
        {/* End hero section */}

        {/* Start dataSelectWorks section */}
        <section id="works">
          <div className="container">
            <motion.h2
              className="text-secondary h1"
              variants={titleVariants}
              initial="hidden"
              animate="visible">
              Select Works
            </motion.h2>
            <motion.div
              className="row"
              variants={containerVariants}
              initial="hidden"
              animate="visible">
              {/* Display grid of select case studies */}
              {
                dataSelectWorks.map((caseStudy) => (
                  <motion.div key={caseStudy.id} className="col-sm-6 mt-5" variants={itemVariants}>
                    <ContentCard
                      id={caseStudy.id}
                      title={caseStudy.title}
                      subtitle={caseStudy.subtitle}
                      // img={caseStudy.img}
                      url={caseStudy.url}
                      imgObj={getQueriedWorkImages(data, caseStudy.id)}
                    />
                  </motion.div>
                ))
              }
            </motion.div>
          </div>
        </section>
        {/* End dataSelectWorks section */}

        {/* <!-- Start Blog Section --> */}
        <section id="blog">
          <div className="container">
            <div className="row row-eq-height">
              <div className="col-sm-12">
                <h2 className="h1 text-secondary my-3">Writing</h2>
                <p>I write about app design and Android development on <a href="https://blog.iamsuleiman.com/" target="_blank" rel="noreferrer">my blog</a>.</p>
              </div>
            </div>
            <div className="row mt-0 mt-md-4">
              {
                dataBlogArticles.map((article) => (
                  // Using col-md-4 since we have only 3 articles to display. 
                  // Adding a margin-top for smaller breakpoints so there's more vertical spacing between stacked items.
                  // TODO: Maybe col count should be dynamic in the future? Overkill for now...
                  <div className="col-md-4 mt-3 mt-md-0" key={article.title}>
                    <ContentCard
                      id={article.id}
                      title={article.title}
                      url={article.url}
                      imgObj={getQueriedWorkImages(data, article.id)}
                    />
                  </div>
                ))
              }
              {/* <!-- Subscribe box --> */}
              {/* <div className="col-md-4">
                {{>subscribe-optin}}
              </div> */}
            </div>
          </div>
        </section>
        {/* <!-- End Blog Section --> */}

        {/* Start Contact Section */}
        <section id="contact">
          <div className="container text-center">
            <h2 className="h1 text-secondary my-3">Say Hi!</h2>
            <div className="row">
              <div className="col-sm-12">
                <p>If you'd like to work with me, or just chat, feel free to get in touch.</p>
                <a href="mailto:hi@iamsuleiman.com?Subject:-via%20Your%20Portfolio" className="btn btn-primary mt-4"><Mail />Get in touch</a>

              </div>
            </div>
          </div>
        </section>
        {/* End Contact Section */}
      </>
    } />
  )
}


/**
 * Queries
 */

// Portfolio works - Query fragment
export const workImage = graphql`
fragment workImage on File {
    childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
    }
}
`
// Portfolio works - Query
export const queryWork = graphql`
query {
    image1: file(relativePath: { eq: "works/thumb_portfolio_buzzfeedplus.png" }) {
        ...workImage
    }
    image2: file(relativePath: { eq: "works/thumb_portfolio_bam.png" }) {
      ...workImage
    }
    image3: file(relativePath: { eq: "works/thumb_portfolio_bfappds.png" }) {
        ...workImage
    }
    image4: file(relativePath: { eq: "works/thumb_portfolio_carpoolworld.png" }) {
        ...workImage
    }
    image5: file(relativePath: { eq: "works/thumb_portfolio_gmaps.png" }) {
        ...workImage
    }
    image6: file(relativePath: { eq: "works/thumb_portfolio_causehero.png" }) {
        ...workImage
    }
    image7: file(relativePath: { eq: "works/thumb_portfolio_amazon.png" }) {
      ...workImage
  }
    imageArticle1: file(relativePath: { eq: "article/article_horizontal_lists_mobile.png" }) {
        ...workImage
    }
    imageArticle2: file(relativePath: { eq: "article/text-overlay-image-background-header.png" }) {
      ...workImage
    }
  imageArticle3: file(relativePath: { eq: "article/loading_spinner_skeleton_screen_header.png" }) {
    ...workImage
  }
}
`
// Since portfolio work cards are looped, we use this to get 
// the right queried image from data object based on the key
function getQueriedWorkImages(data, key) {
  switch (key) {
    case 'amazontokens':
      return getImage(data.image7);
    case 'buzzfeed':
      return getImage(data.image1);
    case 'bam':
      return getImage(data.image2);
    case 'bfappds':
      return getImage(data.image3);
    // case 'carpoolworld':
    //   return getImage(data.image4);
    // case 'gmaps':
    //   return getImage(data.image5);
    // case 'causehero':
    //   return getImage(data.image6);

    case 'blog-horizontallist':
      return getImage(data.imageArticle1);
    case 'blog-emptystate':
      return getImage(data.imageArticle2);
    case 'blog-skeleton':
      return getImage(data.imageArticle3);

    default:
      return getImage(data.image4);
  }
}